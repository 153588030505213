import React from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import Button from "@components/elements/button"
import Anchor from "@components/elements/anchor"
import { graphql } from "gatsby"
import { processImages } from "@utils/process-images"
import Image from "@components/image"
import styled from "styled-components"
import media from "../styles/media"
import Html from "@components/html"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const AssetContainer = ({
  images,
  image,
  title,
  description,
  link,
  background,
}) => {
  return (
    <Grid
      alignItems="center"
      borderRadius="1rem"
      columns="1fr 1fr"
      columnsSm="1fr"
      overflow="hidden"
    >
      <Grid alignItems="center" height="100%" background={background}>
        <Image
          style={{ height: "100%" }}
          objectFit="contain"
          file={images[image]}
        />
      </Grid>
      <Flex padding="2rem" gap="2rem">
        <Heading as="h2" fontSize="2rem">
          {title}
        </Heading>
        <Paragraph fontSize="1rem">{description}</Paragraph>
        <Anchor color="#18aed8" arrow link={link}>
          Learn More
        </Anchor>
      </Flex>
    </Grid>
  )
}

AssetContainer.propTypes = {
  images: PropTypes.any,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
}

const flexGap = { gap: "12rem", gapMd: "8rem", gapSm: "6rem" }

const ImageWrapper = styled.div`
  ${media.sm`
    order: -1;
  `}
`

const BIPage = ({ data }) => {
  const images = processImages(data.images.edges)
  const page = data.page

  return (
    <Layout fullWidth marginless seo={page.seo}>
      <Flex {...flexGap} margin="0 0 8rem">
        <Div
          background="#e0f8ff"
          maxWidth="1400px"
          width="100%"
          margin="0 auto 0"
          padding="8rem 0 6rem"
        >
          <Grid
            columns="1fr 1fr"
            columnsSm="1fr"
            alignItems="center"
            gap="2rem"
            container
          >
            <Flex gap="2rem">
              <Heading color="#013950">{page.heading}</Heading>
              <Paragraph fontSize="1.4rem" lineHeight="160%" fontWeight="600">
                {page.description}
              </Paragraph>
              <Button.Klips
                alignSelf="flex-start"
                lineHeight="1"
                padding="1rem 2.75rem"
              />
            </Flex>
            <ImageWrapper>
              <Image file={images["3aaa3be4-7968-4bd6-90ce-b464a28bd087"]} />
            </ImageWrapper>
          </Grid>
        </Div>

        <Flex {...flexGap} margin="0 0 8rem">
          <Container text>
            <Flex gap="2rem">
              <Html parseElements html={page.body_main} />
            </Flex>
          </Container>

          <Container>
            <Flex {...flexGap}>
              <Grid
                columns="2fr 3fr"
                columnsMd="1fr"
                gap="4rem"
                alignItems="center"
              >
                <Image file={images["c6fe0fc0-6773-4448-86b2-8f9b395f893d"]} />
                <Flex gap="2rem">
                  <Html parseElements html={page.body_trust} />
                  <div>
                    <Image
                      file={images["9432b221-1c46-461c-998a-9e9b02b7d9a3"]}
                    />
                  </div>
                </Flex>
              </Grid>
            </Flex>
          </Container>
        </Flex>

        <Div background="#e0f8ff" padding="8rem 0">
          <Flex center textContainer gap="2rem">
            <Html parseElements html={page.body_tool} />
          </Flex>

          <Grid
            alignItems="center"
            columns="1fr 1fr"
            columnsSm="1fr"
            container
            gap="4rem"
            style={{ margin: "4rem auto 0" }}
          >
            <Flex gap="2rem">
              <Html parseElements html={page.body_tool_secondary} />
            </Flex>
            <Image file={images["cd7729f3-b588-4b11-991c-7dad2b569bd6"]} />
          </Grid>
        </Div>

        <Flex textContainer gap="2rem">
          <Html parseElements html={page.body_secondary} />
        </Flex>

        <Container text>
          <Flex gap="2rem">
            <AssetContainer
              images={images}
              image={"5f997110-d578-48a0-a207-2156a5353ce4"}
              title="Choosing the Right Dashboard Report"
              description={`Dashboard reports are used to track the health of an organization by reporting on KPIs, business metrics, and analytics. Make sure you're selecting the right one for you.`}
              link="https://www.klipfolio.com/blog/starter-guide-to-dashboards"
            />
            <AssetContainer
              images={images}
              image={"67e11c3d-27d4-4a0c-a43f-8b9880f6e70d"}
              title="Dashboard Design"
              description={`Designing a dashboard isn't always straightforward - it takes time, effort, and thought to develop one that helps you move the needle.`}
              link="https://www.klipfolio.com/blog/starter-guide-to-dashboard-design"
            />
            <AssetContainer
              images={images}
              image={"c594da86-5ea6-40da-bb48-5cbf20874b1a"}
              title="Building Data Visualizations"
              description={`Everyone loves the idea of having their data visualized in an easy-to-read way, but it's not always so easy to build. Klipfolio makes this data analysis process more simple.`}
              link="https://www.klipfolio.com/blog/starter-guide-to-data-visualizations"
            />
            <AssetContainer
              images={images}
              image={"ea741676-d64b-40e3-aa1a-7a29ccd4a6d3"}
              title="Data Warehouse Reporting"
              description={`For a company to be truly data-driven, it needs access to a staggering amount of data. That’s where a data warehouse comes in.`}
              link="https://www.klipfolio.com/solutions/data-warehouse-dashboard"
            />
            <AssetContainer
              images={images}
              image={"6ec22fbd-f6a7-4428-81e9-48ba40ef4e97"}
              title="Mobile BI Dashboard Design"
              description={`You're on the move - make sure your dashboard is designed to be too!`}
              link="https://www.klipfolio.com/blog/starter-guide-to-dashboard-design"
            />
          </Flex>
        </Container>

        <Grid
          alignItems="center"
          container
          gap="2rem"
          columns="1fr 1fr"
          columnsSm="1fr"
        >
          <Heading as="h2">Immediate support when you need it most</Heading>
          <Flex gap="2rem" justifyContent="flex-start">
            <div>
              <Image
                height={135}
                width={177}
                file={images["47a53593-0c3a-4a55-bd30-026dce57c5f7"]}
              />
            </div>
            <Paragraph>
              &quot;Fantastic company. The support they offer even at the
              regular levels is above and beyond and almost immediately you get
              a hold of someone knowledgeable in how to fix it!&quot;
            </Paragraph>
            <Paragraph fontSize="1rem">
              Steven Meyer, Internal Operations,&nbsp;
              <Anchor link="https://www.skylinenet.net/">
                Skyline Technology Services
              </Anchor>
            </Paragraph>
          </Flex>
        </Grid>
      </Flex>

      <KlipsEOPCta />
    </Layout>
  )
}

BIPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BIPage

export const pageQuery = graphql`
  query BIQuery {
    page: biAnalytics {
      body_main
      body_main_images {
        cdn
        placeholder
        title
      }
      body_secondary
      body_secondary_images {
        cdn
        placeholder
        title
      }
      body_tool
      body_tool_images {
        cdn
        placeholder
        title
      }
      body_tool_secondary
      body_tool_secondary_images {
        cdn
        placeholder
        title
      }
      body_trust
      body_trust_images {
        cdn
        placeholder
        title
      }
      description
      heading
      seo {
        ...seoData
      }
    }
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "3aaa3be4-7968-4bd6-90ce-b464a28bd087"
            "c6fe0fc0-6773-4448-86b2-8f9b395f893d"
            "9432b221-1c46-461c-998a-9e9b02b7d9a3"
            "cd7729f3-b588-4b11-991c-7dad2b569bd6"
            "5f997110-d578-48a0-a207-2156a5353ce4"
            "67e11c3d-27d4-4a0c-a43f-8b9880f6e70d"
            "c594da86-5ea6-40da-bb48-5cbf20874b1a"
            "ea741676-d64b-40e3-aa1a-7a29ccd4a6d3"
            "6ec22fbd-f6a7-4428-81e9-48ba40ef4e97"
            "1fc90bb1-f3a2-4e4d-8510-06cecb8df01d"
            "7f19cf5b-32df-4dca-8c3d-ef9984ed0d1f"
            "47a53593-0c3a-4a55-bd30-026dce57c5f7"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
